import { useRouter } from 'next/router';
import React from 'react';

export const useRetpath = () => {
    const { retpath } = useRouter().query;

    return React.useMemo(() => {
        if (typeof retpath !== 'string') {
            return undefined;
        }

        try {
            const { protocol, host, pathname, search } = new URL(retpath, 'http://n');

            // Return nothing if retpath contains absolute url
            if (protocol !== 'http' || host !== 'n') {
                return undefined;
            }

            return `${pathname}${search}`;
        } catch {
            return undefined;
        }
    }, [retpath]);
};
